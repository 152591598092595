<template>
    <div>
        <b-card>
            <app-view-table :title="trans('settings-national-holidays',167)" :loading="loading_list" :topFiltersCount="1" :isTitleTotal="true" :filters.sync="filters" :items="items" :fields="fields"> 

                <template v-slot:filter-col-1>
                    <app-year v-model="filters.year_dates"  />
                </template>


                <template v-slot:menu-items="cell">
                     <b-dropdown-item @click="changeName(cell.data.item)">{{trans('change',175)}}</b-dropdown-item> 
                     <b-dropdown-item @click="deleteItem(cell.data.item.id_freedays_data_main)">{{trans('remove',175)}}</b-dropdown-item>
                </template> 


                <template v-slot:buttons>
                    <app-button type="primary" @click="addFreeDays()">{{trans('settings-add-national-holidays',167)}}</app-button>
                </template>   
            </app-view-table>
        </b-card>
        <settings-free-days-add v-if="mFeeDaysAdd.show" :show.sync="mFeeDaysAdd.show" :result.sync="mFeeDaysAdd.result" :args="mFeeDaysAdd.args" :return.sync="items" />
        <settings-free-days-edit v-if="mFreeDaysEdit.show" :show.sync="mFreeDaysEdit.show" :result.sync="mFreeDaysEdit.result" :args="mFreeDaysEdit.args" :return.sync="items" />
    </div>
</template>
<script>


import axios from "@axios";
import SettingsFreeDaysAdd from './SettingsFreeDaysAdd.vue';
import SettingsFreeDaysEdit from './SettingsFreeDaysEdit.vue';
import AppAlerts from '@core/scripts/AppAlerts';
import AppYear from '@core/components/AppYear.vue';
import moment from "moment";

export default {
    components:{
        SettingsFreeDaysAdd,
        SettingsFreeDaysEdit,
        AppYear
    },
    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            loading_list: false,
            filters: {
                sorting: {
                        field_name: 'date',
                        sort_direction: 1,
                        changed: 0
                },
                page: 1,
                per_page: 10,
                search: "",
                changed: 0,
                year_dates: moment().format('YYYY'),
            },
            alertClass: new AppAlerts(),
            fields: [
                
                { key: "name", label: this.trans('full-name',3), thStyle: { width: "100%"  }, visible: true},
                { key: "date", label: this.trans('date',182), thStyle: { "min-width": "150px", "max-width": "400px"}, visible: true},
                { key: "time_from", label: this.trans('from',182), thStyle: { "min-width": "150px", "max-width": "400px"}, visible: true},
                { key: "time_to", label: this.trans('settings-to',207), thStyle: { "min-width": "150px", "max-width": "400px"}, visible: true}
            ],
            items: [],

             mFeeDaysAdd: { show: false, args: {
                    filters: null,
                }, result: 0, return: null },

            mFreeDaysEdit: { show: false, args: {
                    free_days_data:{
                        id: null,
                        date: null,
                        name: null,
                        time_from: null,
                        time_to: null
                    },
                    filters: null,
                }, result: 0, return: null },
        };
    },


    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("settings/freeDays/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
                
        },

        deleteItem: function(id_freedays_data_main) {
            this.alertClass.openAlertConfirmation(this.trans('settings-continue-confirmation',167)).then(res => {
                if(res == true){
                    this.loading_list = true;
                    axios
                        .post("settings/freeDays/delete", {
                            id_freedays_data_main : id_freedays_data_main,
                            filters: JSON.stringify(this.filters),
                        })
                        .then((res) => {
                            this.filters.page = res.data.page;
                            this.items = res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading_list = false;
                        });
                }
            });
                
        },



        changeName(item) {
            this.mFreeDaysEdit.args.free_days_data.id_freedays_data_main = item.id_freedays_data_main;
            this.mFreeDaysEdit.args.free_days_data.date = item.date
            this.mFreeDaysEdit.args.free_days_data.name = item.name ? item.name: '';
            this.mFreeDaysEdit.args.free_days_data.time_from = item.time_from;
            this.mFreeDaysEdit.args.free_days_data.time_to = item.time_to;
            this.mFreeDaysEdit.args.filters = this.filters;
            this.mFreeDaysEdit.show = true;
            
        },

        addFreeDays(){
            this.mFeeDaysAdd.args.filters = this.filters;
            this.mFeeDaysAdd.show = true;
        },

        onCreateThis() { 

            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.year_dates', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });
        },
    }
 
};
</script>