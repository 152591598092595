<template>
    <b-modal ref="modal-edit" v-model="computedShow" no-close-on-backdrop :title="trans('settings-change-national-holidays',167)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">

                 <app-row-left-label :label="trans('full-name',3)">
                    <app-input v-model="cArgs.free_days_data.name" validatorName="Naam" validatorRules="required" mask="lettersE6-50" />
                </app-row-left-label>

                <app-row-left-label :label="trans('date',182)">
                    <app-date v-model="cArgs.free_days_data.date" validatorName="Date" validatorRules="required"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('settings-from-to',207)" :slots="[6,6]" >
                     <template v-slot:col-1 >
                        <app-time  :acceptedTime=" '00:00-' + cArgs.free_days_data.time_to" v-model="cArgs.free_days_data.time_from" :minuteStep="1"/>
                     </template>

                     <template v-slot:col-2 >
                        <app-time :acceptedTime=" cArgs.free_days_data.time_from + '-23:59'" v-model="cArgs.free_days_data.time_to" :minuteStep="1"/>
                     </template>
                 </app-row-left-label>

            </b-form>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="changeSection" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data() {
        return {
            loading: false,
            date: null,
            name: ''
        };
    },

    methods: {
       changeSection() {

            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post("settings/freeDays/change", {
                            id_freedays_data_main: this.cArgs.free_days_data.id_freedays_data_main,
                            date: this.cArgs.free_days_data.date,
                            name: this.cArgs.free_days_data.name,
                            time_from: this.cArgs.free_days_data.time_from,
                            time_to: this.cArgs.free_days_data.time_to,
                            filters: JSON.stringify(this.cArgs.filters)
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            this.cReturn =  res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading = false;
                        });
                    }
            })
        },
        
        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped></style>
